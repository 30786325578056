<div id="layout-wrapper">
    <div class="main-content minimum">
        <div class="page-content minimum">
            <!-- content -->
            <router-outlet></router-outlet>
        </div>
    </div>
    <!-- footer -->
    <app-footer footerClassName="footer minimum"></app-footer>
</div>
<app-rightsidebar></app-rightsidebar>

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthDataModel, SessionInfoService } from 'degarmo-lib';
import { AccountInfoService } from 'degarmo-lib';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private sessionInfoService: SessionInfoService,
        private accountInfoService: AccountInfoService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let url = request.url;
        const auth: AuthDataModel = this.sessionInfoService.getAuthenticationInformation();
        const impersonating = this.accountInfoService.data.impersonating;

        // Check for impersonation id and includes it if necessary
        if (url.indexOf('http') !== -1 && impersonating.isImpersonating) {
            if (url.indexOf('{proctor:') !== -1 && impersonating.impersonationType === 'proctor')
                url = this.templateReplace(url, '{proctor:', '}', impersonating.impersonatingID);
        }

        if (url.indexOf('http') !== -1 && !impersonating.isImpersonating) {
            if (url.indexOf('{all:') !== -1) {
              url = this.templateReplace(url, '{all:', '}', 0);
            }
          }
    
        url = this.templateRemove(url, '{', '}');

        while (url.indexOf('{') > -1) {
        url = url.substring(0, url.indexOf('{')) + url.substring(url.indexOf('}')+1);
        }

        if (url[url.length - 1] === '/')
            url = url.substring(0, url.length - 1);
        
        if (auth && auth.accessToken && auth.isLoggedIn === true) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${auth.accessToken}`
                }
            });
        }
        
        if (url !== request.url) {
            request = request.clone({
                url: url
            });
        }

        return next.handle(request);
    }

    templateReplace = (target: string, startStr: string, endStr: string, replaceValue: any) => {
        let output = target;
        let toReplace = '';
        let toFullyReplace = '';
        let replacedValue = '';

        while (output.indexOf(startStr) > -1) {
          const startIndex = output.indexOf(startStr);
          toReplace = output.substring(startIndex, output.indexOf(endStr, startIndex) + endStr.length);
          replacedValue = toReplace.replace(startStr, '').replace(endStr, '');
          if (replacedValue.indexOf('[') > -1) {
            toFullyReplace = replacedValue.substring(replacedValue.indexOf('['), replacedValue.indexOf(']') + 1);
            replacedValue = replacedValue.replace(toFullyReplace, replaceValue);
          }
          output = output.replace(toReplace, replacedValue);
        }

        return output;
    }

    templateRemove = (target: string, startStr: string, endStr: string) => {
        let output = target;

        while (output.indexOf(startStr) > -1) {
          output = output.replace(output.substring(output.indexOf(startStr), output.indexOf(endStr) + endStr.length), '');
        }

        return output;
    }
}
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { NgbNavModule, NgbAccordionModule, NgbTooltipModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxEchartsModule } from 'ngx-echarts';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxCaptureModule } from 'ngx-capture';

import { SharedModule, ComponentCanDeactivateGuard } from 'degarmo-lib';
import { ExtrapagesModule } from './modules/extrapages/extrapages.module';

import { LayoutsModule } from './modules/layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { AuthenticationModule } from './modules/core/authentication/authentication.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NoCacheHeadersInterceptor } from './modules/core/authentication/interceptors/no-cache-headers.interceptor';
import { InterviewBuilderRulesResolver } from './modules/features/interviews/interview-builder/interview-builder-rules.resolver';
import { PackageBuilderResolver } from './modules/features/editors/packages/package-builder/package-builder.resolver';

import 'froala-editor/js/plugins.pkgd.min.js';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AuthenticationModule,
    LayoutsModule,
    AppRoutingModule,
    ExtrapagesModule,
    CarouselModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      timeOut: 7000,
      positionClass: 'toast-top-full-width',
    }),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    NgxSpinnerModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    SharedModule,
    NgbModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    NgxCaptureModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true },
    InterviewBuilderRulesResolver,
    PackageBuilderResolver,
    ComponentCanDeactivateGuard,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}

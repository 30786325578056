import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from './modules/core/authentication/guards/authentication.guard';
import { LayoutComponent } from './modules/layouts/layout.component';
import { Page404Component } from './modules/extrapages/page404/page404.component';
import { MinimumLayoutComponent } from './modules/layouts/minimum-layout/minimum-layout.component';
import { UserSecurityGuard } from './modules/core/authentication/guards/user-security.guard';

let routes: Routes;

routes = [
  { path: 'account', loadChildren: () => import('./modules/account/account.module').then((m) => m.AccountModule) },
  {
    path: '',
    component: LayoutComponent,
    loadChildren: () => import('./modules/features/features.modules').then((m) => m.FeaturesModule),
    canActivate: [UserSecurityGuard],
  },
  {
    path: 'digital-interview-guide',
    component: MinimumLayoutComponent,
    loadChildren: () =>
      import('./modules/features/interviews/interview-guide/interview-mode/interview-mode.module').then((m) => m.InterviewModeModule),
    canActivate: [AuthenticationGuard, UserSecurityGuard],
  },
  // path is used for non login users and does not show the side bar
  {
    path: 'interview-guide-with-params',
    component: MinimumLayoutComponent,
    loadChildren: () =>
      import('./modules/features/interviews/interview-guide/interview-mode/interview-mode-with-url-params.module').then(
        (m) => m.InterviewModeWithUrlParamsModule
      ),
  },
  {
    path: 'interview-data-entry-lookup',
    component: MinimumLayoutComponent,
    loadChildren: () =>
      import('./modules/features/interviews/interview-guide/interview-guide-data-entry/interview-guide-data-entry.module').then(
        (m) => m.InterviewGuideDataEntryModule
      ),
  },
  {
    path: 'interview-guide-download',
    component: MinimumLayoutComponent,
    loadChildren: () =>
      import('./modules/features/interviews/interview-guide/interview-guide-document-download/interview-guide-document-download.module').then(
        (m) => m.InterviewModeWithUrlParamsModule
      ),
  },
  {
    path: 'pg-interview-guide-demo',
    component: MinimumLayoutComponent,
    loadChildren: () =>
      import(
        './modules/features/interviews/interview-guide/custom-components/proctor-and-gamble/proctor-and-gamble-interview-guide-demo/proctor-and-gamble-interview-guide-demo.module'
      ).then((m) => m.ProctorAndGambleInterviewGuideDemoModule),
    data: {
      authenticate: false,
    },
  },
  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-minimum-layout',
    templateUrl: './minimum-layout.component.html',
    styleUrls: ['./minimum-layout.component.scss'],
    standalone: false
})
export class MinimumLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

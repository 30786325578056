import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, interval, lastValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { IdleLogoutModalComponent } from './modules/account/auth/idle-logout-modal/idle-logout-modal.component';
import {
  DeviceSize,
  AccountInfoService,
  AlertsService,
  CompanyService,
  DeviceService,
  SessionInfoService,
  UrlService,
  UserAccountService,
  UtilitiesService,
  AuthDataModel,
  AccountInfo,
  SessionKey,
} from 'degarmo-lib';
import Swal from 'sweetalert2';
import packageJson from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  @ViewChild('screen', { static: true }) screen: any;
  previousUrl: string = null;
  currentUrl: string = null;
  nextUrl: string = null;

  userActivity: any;
  userInactive: Subject<any> = new Subject();

  // decides how long it takes to show the inactivity modal
  inactiveTimerInMinutes: number = 5;
  // the amount of time (in minute) to determine how long the modal can be open before logging out automatically
  minutesToLogoutInModal: number = 1;
  tokenRefreshIntervalInMilliSeconds: number = 600000; // milliseconds
  tokenRefreshInterval: Observable<number>;
  tokenRefreshIntervalSubscription: Subscription;
  modalRef: any;

  constructor(
    private accountInfoService: AccountInfoService,
    public alertsService: AlertsService,
    private urlService: UrlService,
    private deviceService: DeviceService,
    private router: Router,
    private modalService: NgbModal,
    private userAccountService: UserAccountService,
    private sessionInfoService: SessionInfoService,
    private utilitiesService: UtilitiesService,
    private companyService: CompanyService
  ) {}

  ngOnInit() {
    // set browser name
    this.deviceService.getBrowserName();
    this.checkUserInfo();
    this.manageSignedOutInOtherTabs();
    this.addLoginStatusSubscription();
    this.addUserInactiveSubscription();
    this.addRouterEventSubscriptions();
    this.handleWindowResize();
    window.onresize = this.handleWindowResize;
    (async () => {
      try {
        await this.checkVersion();
        await lastValueFrom(this.loadCompanyImages());
        if (location.hash.indexOf('#') !== -1) {
          this.handleLegacyURL(location.hash);
        }
      } catch (err) {
        this.userAccountService.logOutUser();
      }
    })();
  }

  checkVersion = async () => {
    const currentVersionNumber: string = await lastValueFrom(this.utilitiesService.getCurrentProjectVersion(1));

    if (packageJson.version.trim() !== currentVersionNumber.trim()) {
      // force the browser to refresh
      window.location.reload();
    }
  };

  addRouterEventSubscriptions = () => {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;

      this.urlService._previousUrl = this.previousUrl;
      this.urlService._currentUrl = this.currentUrl;
      this.urlService.setPreviousUrl(this.previousUrl);
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      this.nextUrl = event.url;

      this.urlService._nextUrl = this.nextUrl;
      this.urlService.setNextUrl(this.nextUrl);
    });
  };

  addLoginStatusSubscription = () => {
    this.userAccountService.subscribeToLoginChanged((loggedIn: boolean) => {
      if (loggedIn) {
        this.setIdleTimer();
        const authInfo: AuthDataModel = this.sessionInfoService.getConstant(SessionKey.AuthInformation, 'object', false);

        if (!this.tokenRefreshInterval && authInfo.isLoggedIn) {
          this.tokenRefreshInterval = interval(this.tokenRefreshIntervalInMilliSeconds);
          this.tokenRefreshIntervalSubscription = this.tokenRefreshInterval.subscribe({
            next: (_) => {
              this.updateToken();
            },
          });
        }
      } else {
        this.tokenRefreshIntervalSubscription?.unsubscribe();
        delete this.tokenRefreshInterval;
        delete this.tokenRefreshIntervalSubscription;
      }
    });
  };

  addUserInactiveSubscription() {
    this.userInactive.subscribe(() => {
      if (this.accountInfoService.data.account && this.accountInfoService.data.account.isAuthenticated) {
        // open modal
        if (!this.modalRef) {
          this.modalRef = this.modalService.open(IdleLogoutModalComponent, {
            size: 'lg',
            centered: true,
          });
          this.modalRef.componentInstance.minutesToLogout = this.minutesToLogoutInModal;
          this.modalRef.dismissed.subscribe({
            next: (res) => {
              this.modalRef = null;
              clearTimeout(this.userActivity);

              this.setIdleTimer();
              if (res && res.logout) {
                this.userAccountService.logOutUser();
              }

              if (res && res.continue) {
                this.updateToken();
              }

              if (res && res.showCloseModal) {
                // close swal
                Swal.close();

                this.modalRef = this.modalService.open(IdleLogoutModalComponent, {
                  size: 'lg',
                  centered: true,
                  animation: false,
                });

                this.modalRef.componentInstance.minutesToLogout = 0;
                this.modalRef.componentInstance.description = 'You were signed out due to inactivity.';
                this.modalRef.componentInstance.loggedOut = true;

                this.modalRef.dismissed.subscribe({
                  next: () => {
                    delete this.modalRef;
                  },
                });
              } else {
                delete this.modalRef;
              }
            },
          });
        }
      }
    });
  }

  checkUserInfo = () => {
    // check if the session contains privateKey. If not, set it
    if (!localStorage.getItem(SessionKey.PrivateKey)) localStorage.setItem(SessionKey.PrivateKey, this.utilitiesService.createID(500));

    let authInfo: AuthDataModel = this.sessionInfoService.getConstant(SessionKey.AuthInformation, 'object', false);
    const account: AccountInfo = this.sessionInfoService.getConstant(SessionKey.AccountData, 'object', true);

    if (authInfo && authInfo.info && account) {
      this.accountInfoService.data = account;
      this.accountInfoService.loadUserLoginInfo();
      this.userAccountService.broadcastLoginChanged(this.accountInfoService.data.account.isAuthenticated);
    }
  };

  /**
   * Updates the token
   */
  updateToken = () => {
    const authData: AuthDataModel = this.sessionInfoService.getConstant(SessionKey.AuthInformation, 'object', false);
    if (authData?.refreshToken && authData?.isLoggedIn) {
      const token = `refresh_token=${authData.refreshToken}&grant_type=refresh_token`;

      this.userAccountService.getToken(token).subscribe({
        next: (authInfo: AuthDataModel) => {
          if (authInfo.accessToken && authInfo.refreshToken) {
            authData.accessToken = authInfo.accessToken;
            authData.expiresIn = authInfo.expiresIn;
            authData.refreshToken = authInfo.refreshToken;
            authData.tokenType = authInfo.tokenType;
            this.sessionInfoService.setConstant(SessionKey.AuthInformation, authData, true);
          }
        },
      });
    }
  };

  loadCompanyImages = () => {
    return new Observable((subscriber) => {
      (async () => {
        // let imagePath: string = '/assets/images/';
        let imagePath: string = 'assets/images/';
        const host = window.location.host;

        if (
          this.sessionInfoService.getConstant(SessionKey.CompanyLogo, 'object', false) &&
          host === this.sessionInfoService.getConstant(SessionKey.CompanyLogo, 'object', false).host
        ) {
          this.accountInfoService.logo = this.sessionInfoService.getConstant(SessionKey.CompanyLogo, 'object', false);
          subscriber.next();
          subscriber.complete();
        }

        if (host.includes('localhost')) {
          this.accountInfoService.logo = {
            headerLogoURL: `${imagePath}degarmoHeaderLogo.png`,
            mainLogoURL: `${imagePath}degarmoLogo.png`,
            smLogo: `${imagePath}degarmo_logo_sm.png`,
            loginText: `Log in here to access DeGarmo's Talent Platform.`,
            host: host,
          };
          this.sessionInfoService.setConstant(SessionKey.CompanyLogo, this.accountInfoService.logo, false);
          subscriber.next();
          subscriber.complete();
        }

        try {
          const degarmoHostNames: string[] = ['dashboard.degarmo.com', 'dashboard.dgstaging.com', 'dashboard.degarmotest.com'];

          if (degarmoHostNames.indexOf(host) !== -1 || host.includes('localhost')) {
            this.accountInfoService.logo = {
              headerLogoURL: `${imagePath}degarmoHeaderLogo.png`,
              mainLogoURL: `${imagePath}degarmoLogo.png`,
              smLogo: `${imagePath}degarmo_logo_sm.png`,
              loginText: `Log in here to access DeGarmo's Talent Platform.`,
              host: host,
            };
            this.sessionInfoService.setConstant(SessionKey.CompanyLogo, this.accountInfoService.logo, false);
            subscriber.next();
            subscriber.complete();
          } else {
            this.accountInfoService.logo = await lastValueFrom(this.companyService.getCompanyCustomThemeByDomain(host));
            this.sessionInfoService.setConstant(SessionKey.CompanyLogo, this.accountInfoService.logo, false);
            subscriber.next();
            subscriber.complete();
          }
        } catch {
          this.accountInfoService.logo = {
            headerLogoURL: `${imagePath}degarmoHeaderLogo.png`,
            mainLogoURL: `${imagePath}degarmoLogo.png`,
            smLogo: `${imagePath}degarmo_logo_sm.png`,
            loginText: `Log in here to access DeGarmo's Talent Platform.`,
            host: host,
          };
          this.sessionInfoService.setConstant(SessionKey.CompanyLogo, this.accountInfoService.logo, false);
          subscriber.next();
          subscriber.complete();
        }
      })();
    });
  };

  manageSignedOutInOtherTabs = () => {
    // detect if the user is logged out in another tab, if so it logs out in all other tabs
    window.addEventListener(
      'storage',
      (e) => {
        const privateKey = localStorage.getItem('privateKey');

        if (privateKey === undefined || privateKey === null) {
          this.router.navigate(['/account/login']);
          this.alertsService.doneLoading(true);
        }
      },
      false
    );
  };

  handleWindowResize = () => {
    const deviceSize: DeviceSize = {
      height: window.innerHeight,
      width: window.innerWidth,
    };

    this.deviceService.setDeviceSize(deviceSize);
  };

  handleLegacyURL = (hash: string) => {
    let newPath: string = '';
    const url = hash.toLowerCase();

    if (url.indexOf('#/interview/interview-guide/digital/start') !== -1) {
      newPath = 'interview-guide-with-params';
    } else if (url.indexOf('#/interview/interview-guide/document/start') !== -1) {
      newPath = 'interview-data-entry-lookup';
    } else if (url.indexOf('#/interview/interview-guide/preview') !== -1) {
      newPath = 'interview-guide-with-params/preview';
    } else if (url.indexOf('#/interview/interview-guide-download') !== -1) {
      newPath = 'interview-guide-download';
    } else if (url.indexOf('#/talent/candidate-lookup') !== -1) {
      newPath = 'talent/participant/lookup';
    } else if (url.indexOf('#/index/password-reset') !== -1) {
      newPath = 'account/password-reset';
    }

    if (newPath) this.router.navigate([newPath], { queryParams: this.urlService.getUrlParams(hash) });
  };

  setIdleTimer = () => {
    this.userActivity && clearTimeout(this.userActivity);
    this.userActivity = setTimeout(() => {
      this.userInactive.next(undefined);
    }, this.inactiveTimerInMinutes * 60 * 1000);
  };

  @HostListener('click', ['$event'])
  @HostListener('keydown', ['$event'])
  @HostListener('input', ['$event'])
  @HostListener('window:mousemove', ['$event'])
  refreshUserStat() {
    clearTimeout(this.userActivity);
    this.setIdleTimer();
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountInfoService } from 'degarmo-lib';

@Component({
    selector: 'app-page404',
    templateUrl: './page404.component.html',
    styleUrls: ['./page404.component.scss'],
    standalone: false
})

/**
 * Pages-404 component
 */
export class Page404Component implements OnInit {

  constructor(
    private router: Router,
    private accountInfoService: AccountInfoService
  ) { }

  ngOnInit(): void {
    this.router.navigate([this.accountInfoService.data.account.isAuthenticated ? this.accountInfoService.data.account.landingRoute : 'account/login']);
  }

  // getLandingRoute = (): string => {
  //   return this.accountInfoService.data.account.isAuthenticated ? this.accountInfoService.data.account.landingRoute : 'account/login';
  // }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthDataModel, UserAccountService, SessionInfoService, AlertsService, SessionKey } from 'degarmo-lib';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private alertsService: AlertsService, private sessionInfoService: SessionInfoService, private userAccountService: UserAccountService) {}

  isRefreshing: boolean = false;
  refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  TOKEN_HEADER_KEY: string = 'Authorization';

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          return this.handle401Error(request, next);
        }
        return throwError(err);
      })
    );
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    const authData: AuthDataModel = this.sessionInfoService.getConstant(SessionKey.AuthInformation, 'object', false);

    if (!this.isRefreshing && authData && authData.isLoggedIn) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = `refresh_token=${authData.refreshToken}&grant_type=refresh_token`;

      if (token) {
        return this.userAccountService.getToken(token).pipe(
          switchMap((authInfo: AuthDataModel) => {
            this.isRefreshing = false;
            authData.accessToken = authInfo.accessToken;
            authData.expiresIn = authInfo.expiresIn;
            authData.refreshToken = authInfo.refreshToken;
            authData.tokenType = authInfo.tokenType;
            this.sessionInfoService.setConstant(SessionKey.AuthInformation, authData, false);
            this.refreshTokenSubject.next(authData.accessToken);
            this.alertsService.doneLoading(true);
            return next.handle(this.addTokenHeader(request, authData.accessToken));
          }),
          catchError((err) => {
            if (err.status === 400) {
              this.alertsService.error('Please log in again.');
              this.userAccountService.logOutUser();
              this.alertsService.doneLoading(true);
            }
            this.isRefreshing = false;
            return throwError(err);
          })
        );
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((accessToken) => {
          return next.handle(this.addTokenHeader(request, accessToken));
        })
      );
    }
  }

  addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set(this.TOKEN_HEADER_KEY, `Bearer ${token}`) });
  }
}
